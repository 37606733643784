export const WPENGINE_BASE_URL = '/api/wpengine'; // wpengine proxy for cache

// PATH are destinations, not routing regexes
export const SELL_MY_CAR_PATH = '/sell-my-car';
export const SELL_MY_VAN_PATH = '/sell-my-van';

export const SELL_MY_CAR_LANDING_PAGE_V2 = `${SELL_MY_CAR_PATH}/lp`;

export const GUIDES_PATH = '/guides';

export const CONTACT_PATH = 'https://help.motorway.co.uk/hc/en-gb/p/contact';

export const STORIES_PATH = '/stories';

export const BLOG_PATH = '/blog';

export const NEW_LANDING_PAGE_ROUTES = Object.freeze([
  SELL_MY_CAR_PATH,
]);

export const REFACTORED_LANDING_PAGE_ROUTES = Object.freeze([
  SELL_MY_VAN_PATH,
  `${SELL_MY_CAR_PATH}/car-buying-sites`,
  `${SELL_MY_CAR_PATH}/petrol`,
  `${SELL_MY_CAR_PATH}/diesel`,
  `${SELL_MY_CAR_PATH}/car-buyers`,
  `${SELL_MY_CAR_PATH}/to-a-dealer`,
  `${SELL_MY_CAR_PATH}/electric`,
  `${SELL_MY_CAR_PATH}/on-probate`,
  `${SELL_MY_CAR_PATH}/cash-for-:vehicle?`,
  `${SELL_MY_CAR_PATH}/scrap-my-car`,
  `${SELL_MY_CAR_PATH}/on-finance`,
  `${SELL_MY_CAR_PATH}/hybrid`,
]);

export const LANDING_PAGE_ROUTES = Object.freeze([
  `${SELL_MY_CAR_PATH}/:vehicle?-buyers`,
  `${SELL_MY_CAR_PATH}/car-valuation`,
  `${SELL_MY_CAR_PATH}/buy-my-car`,
  `${SELL_MY_CAR_PATH}/suv`,
  `${SELL_MY_CAR_PATH}/moneysupermarket`,
  `${SELL_MY_CAR_PATH}/podcasts`,
  `${SELL_MY_CAR_PATH}/quidco`,
  `${SELL_MY_CAR_PATH}/topcashback`,
  `${SELL_MY_CAR_PATH}/giveasyoulive`,
  `${SELL_MY_CAR_PATH}/easyfundraising`,
  `${SELL_MY_CAR_PATH}/kidstart`,
  `${SELL_MY_CAR_PATH}/refer`,
  `${SELL_MY_CAR_PATH}/ulez`,
  SELL_MY_CAR_LANDING_PAGE_V2,
  `${SELL_MY_CAR_PATH}/cardoffers`,
  `${SELL_MY_CAR_PATH}/gocompare`,
]);

export const VALUATION_TRACKER_ROUTES = Object.freeze([
  '/car-value-tracker',
]);

export const MOT_PAGE_ROUTES = Object.freeze([
  '/mot-check',
  '/mot-check/results',
]);

export const MOT_VALUATION_ROUTE = MOT_PAGE_ROUTES[0];

export const TAX_PAGE_ROUTES = Object.freeze([
  '/car-tax-check',
  '/car-tax-check/results',
]);

export const TAX_VALUATION_ROUTE = TAX_PAGE_ROUTES[0];

export const NO_SITEMAP_ROUTES = Object.freeze([
  SELL_MY_CAR_PATH,
  `${SELL_MY_CAR_PATH}/suv`,
  `${SELL_MY_CAR_PATH}/moneysupermarket`,
  `${SELL_MY_CAR_PATH}/podcasts`,
  `${SELL_MY_CAR_PATH}/quidco`,
  `${SELL_MY_CAR_PATH}/topcashback`,
  `${SELL_MY_CAR_PATH}/car-valuation`,
  `${SELL_MY_CAR_PATH}/giveasyoulive`,
  `${SELL_MY_CAR_PATH}/easyfundraising`,
  `${SELL_MY_CAR_PATH}/kidstart`,
  `${SELL_MY_CAR_PATH}/ulez`,
  `${SELL_MY_CAR_PATH}/refer`,
  SELL_MY_CAR_LANDING_PAGE_V2,
  ...VALUATION_TRACKER_ROUTES,
  '/verify',
  ...REFACTORED_LANDING_PAGE_ROUTES.map((route) => `${route}-v2`),
  `${SELL_MY_CAR_PATH}/cash-for-cars-v2`,
  `${SELL_MY_CAR_PATH}/cardoffers`,
  `${SELL_MY_CAR_PATH}/gocompare`,
]);

export const ULEZ_PAGE_ROUTES = Object.freeze([
  '/ulez-checker',
  '/ulez-checker/:results',
]);

const WORDPRESS_ROUTES_CONFIG = Object.freeze([
  { path: `${SELL_MY_CAR_PATH}/guides/:subdir?`, section: 'guides', zendeskWidget: true },
  { path: '/guides/:subdir?', section: 'guides', zendeskWidget: true },
  { path: '/blog/:year?/:month?/:day?/:post?', section: 'blog', zendeskWidget: true },
  { path: '/stories/:subdir?', section: 'stories', zendeskWidget: true },
  { path: '/press', section: 'press', zendeskWidget: true },
  { path: '/cookies', section: 'cookies' },
  { path: '/privacy', section: 'privacy' },
  { path: '/terms', section: 'terms' },
  { path: '/claims', section: 'claims' },
]);

export const NEW_WORDPRESS_PAGE_ROUTES = Object.freeze([
  `${SELL_MY_CAR_PATH}/guides`,
  ...WORDPRESS_ROUTES_CONFIG.map((config) => config.path),
].map((route) => `/wp${route}`)); // TODO - remove `/wp` after testing

export const WORDPRESS_ROUTES = Object.freeze([
  ...WORDPRESS_ROUTES_CONFIG,
  ...WORDPRESS_ROUTES_CONFIG.map((config) => ({ ...config, noRobots: true, path: `/wp${config.path}` })),
]); // TODO - delete WORDPRESS_ROUTES after testing and export WORDPRESS_ROUTES_CONFIG

export const FULL_LANDING_PAGE_ROUTES = Object.freeze([
  ...LANDING_PAGE_ROUTES,
  ...ULEZ_PAGE_ROUTES,
  ...VALUATION_TRACKER_ROUTES,
  ...NEW_LANDING_PAGE_ROUTES,
  ...REFACTORED_LANDING_PAGE_ROUTES,
  MOT_VALUATION_ROUTE,
  TAX_VALUATION_ROUTE,
]);
